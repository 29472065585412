import { Link, Snackbar, Theme } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/styles";
import MaterialTable from "material-table";
import moment from "moment";
import React, { useState, createRef } from "react";
import { API } from "../../../../helpers";
import { DisplayProducts, StatusBullet } from "../../../../components";
import { exportEntity, applyFilters } from "../../../../helpers/utils";
import { Clear, Check } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  inner: {},
  status: {
    marginRight: theme.spacing(1),
  },
  statusContainer: {
    display: "flex",
    alignItems: "center",
  },
}));

const statusColors: any = {
  sent: "success",
  approved: "info",
  refund: "danger",
};

const OrdersTable = () => {
  const classes = useStyles();

  // Handle toast
  const [openToast, setOpenToast] = React.useState(false);
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenToast(false);
  };
  const [errorMsg, setErrorMsg] = useState("");

  // Table ref
  let tableRef: any = createRef();

  // Set states
  const [isLoading, setIsLoading] = useState(false);

  // Active filter
  const [filterActive, setFilterActive] = useState(false);

  return (
    <div className={classes.inner}>
      <MaterialTable
        isLoading={isLoading}
        title="Order list"
        columns={[
          { title: "#", field: "idOrder", editable: "never" },
          { title: "Firstname", field: "firstname", editable: "never" },
          { title: "Lastname", field: "lastname", editable: "never" },
          {
            title: "Tracking no",
            field: "trackingNumber",
            render: (rowData: any) => {
              if (rowData.trackingNumber) {
                return (
                  <Link
                    component="a"
                    target="_blank"
                    href={`https://www.laposte.fr/outils/suivre-vos-envois?code=${rowData.trackingNumber}`}
                    variant="h6"
                  >
                    {rowData.trackingNumber}
                  </Link>
                );
              }

              return "-";
            },
          },
          {
            title: "Status",
            field: "status",
            render: (rowData: any) => {
              if (rowData.status) {
                return (
                  <div className={classes.statusContainer}>
                    <StatusBullet
                      className={classes.status}
                      color={statusColors[rowData.status]}
                      size="sm"
                    />
                    {rowData.status}
                  </div>
                );
              }
            },
            lookup: { approved: "approved", sent: "sent" },
          },
          {
            title: "Date",
            field: "dateAdd",
            render: (rowData: any) =>
              moment(rowData.dateAdd).format("DD/MM/YYYY"),
            filtering: false,
            editable: "never",
          },
          { title: "Phone", field: "customerMobileNumber" },
          { title: "Brand", field: "source", editable: "never" },
          {
            title: "DPD",
            field: "exportedToDPD",
            editable: "never",
            render: (rowData: any) => {
              return rowData.exportedToDPD ? <Check /> : <Clear />;
            },
            type: "boolean",
          },
        ]}
        data={(query) =>
          new Promise((resolve) => {
            const filter = applyFilters(query.filters);

            API.get("orders", {
              params: {
                pageSize: query.pageSize,
                page: query.page + 1,
                orderBy: query.orderBy?.field || "dateAdd",
                orderDirection: query.orderDirection,
                search: query.search,
                $filter: filter,
              },
            })
              .then((res) => {
                if (res.status === 200) {
                  return res.data;
                }
              })
              .then((data) => {
                setIsLoading(false);
                setErrorMsg("");
                resolve({
                  data: data.orders,
                  page: data.page - 1,
                  totalCount: data.count,
                });
              })
              .catch((err) => {
                setIsLoading(false);
                if (err?.response?.data?.message?.details) {
                  setErrorMsg(err.response.data.message.details[0].message);
                } else {
                  setErrorMsg("Network Error");
                }
                setOpenToast(true);
                resolve({
                  data: [],
                  page: 0,
                  totalCount: 0,
                });
              });
          })
        }
        actions={[
          {
            icon: "filter_list",
            tooltip: "Toogle filter",
            isFreeAction: true,
            onClick: (_event) => setFilterActive(!filterActive),
          },
        ]}
        editable={{
          onRowUpdate: (newData) =>
            new Promise(async (resolve, reject) => {
              try {
                const result = await API.put(
                  `/orders/${newData.idOrder}/${newData.source}`,
                  {
                    status: newData.status,
                    trackingNumber: newData.trackingNumber,
                    customerMobileNumber: newData.customerMobileNumber,
                  }
                );
                if (result.status === 200) {
                  // @todo improve this (do not rerender all table)
                  tableRef?.current?.onQueryChange();
                  resolve();
                }
              } catch (e) {
                reject();
              }
            }),
        }}
        detailPanel={(rowData) => {
          return (
            <DisplayProducts
              address1={rowData.address1}
              postcode={rowData.postcode}
              city={rowData.city}
              email={rowData.email}
              carrier={rowData.carrier}
              products={rowData.products}
              currency={rowData.currency}
              company={rowData.company}
              totalMarchandizePrice={rowData.totalMarchandizePrice}
              totalPaid={rowData.totalPaid}
              totalTax={rowData.totalTax}
              paymentMethods={rowData.paymentMethods}
              discountsOrder={rowData.discountsOrder}
            />
          );
        }}
        onRowClick={(_event, _rowData, togglePanel) => {
          if (togglePanel) togglePanel();
        }}
        options={{
          pageSize: 10,
          pageSizeOptions: [10, 50, 100, 1000, 2000],
          actionsColumnIndex: -1,
          filtering: filterActive,
          exportButton: true,
          exportCsv: async (_columns, data) => {
            setIsLoading(true);
            exportEntity(data, "orders");
            for (const index in data) {
              const order = data[index];
              try {
                const result = await API.put(
                  `/orders/${order.idOrder}/${order.source}`,
                  {
                    exportedToDPD: true,
                  }
                );
                if (result.status === 200) {
                }
              } catch (e) {}
            }
            tableRef?.current?.onQueryChange();
            setIsLoading(false);
          },
        }}
        localization={{
          toolbar: {
            exportName: "Export to DPD",
          },
        }}
      />

      <Snackbar open={openToast} autoHideDuration={6000} onClose={handleClose}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity="error"
        >
          {errorMsg}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default OrdersTable;
