import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { PrivateRouteWithLayout, RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import { NotFound as NotFoundView, OrderList as OrderListView, ReturnList as ReturnListView, SignIn as SignInView } from './views';

const Routes = () => {
  return (
    <Switch>
      <PrivateRouteWithLayout
        Component={OrderListView}
        Layout={MainLayout}
        exact
        path='/orders'
      />
      <PrivateRouteWithLayout
        Component={ReturnListView}
        Layout={MainLayout}
        exact
        path='/returns'
      />
      <RouteWithLayout
        Component={SignInView}
        Layout={MinimalLayout}
        exact
        path='/sign-in'
      />
      <RouteWithLayout
        Component={NotFoundView}
        Layout={MinimalLayout}
        exact
        path='/not-found'
      />
      <Redirect to='/sign-in' />
    </Switch>
  )
}

export default Routes
