import { Link, Snackbar, Theme, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/styles';
import MaterialTable from 'material-table';
import moment from 'moment';
import React, { useState, createRef } from 'react';
import { API } from '../../../../helpers';
import { StatusBullet, DisplayProducts } from '../../../../components';
import { applyFilters } from '../../../../helpers/utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  inner: {},
  status: {
    marginRight: theme.spacing(1)
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
}));

const statusColors: any = {
  pdfGenerated: 'success',
  received: 'primary',
  approved: 'info',
  rejected: 'danger',
  refund: 'warning'
};

const ReturnsTable = () => {
  const classes = useStyles();

  // Handle toast
  const [openToast, setOpenToast] = React.useState(false);
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenToast(false);
  };

  // Refund dialog
  const [openDialog, setOpenDialog] = useState(false);
  const [returnObj, setReturnObj] = useState({
    idOrder: '',
    source: '',
    amountRefunded: 0
  });
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setReturnObj({
      idOrder: '',
      source: '',
      amountRefunded: 0
    });
  }
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setReturnObj({ ...returnObj, [name]: value });
  }
  const handleSave = async () => {
    if (returnObj.idOrder && returnObj.source && returnObj.amountRefunded) {
      const result = await API.put(`/returns/${returnObj.idOrder}/${returnObj.source}`, { status: 'refund', amountRefunded: returnObj.amountRefunded });
      if (result.status === 200) {
        // @todo improve this (do not rerender all table)
        tableRef?.current?.onQueryChange();
      }
    }

    handleCloseDialog();
  }

  const [errorMsg, setErrorMsg] = useState('');

  // Table ref
  let tableRef: any = createRef();

  // Set states
  const [isLoading, setIsLoading] = useState(false);

  // Active filter
  const [filterActive, setFilterActive] = useState(false);

  return (
    <div className={classes.inner}>
      <MaterialTable
        isLoading={isLoading}
        title='Return list'
        tableRef={tableRef}
        columns={[
          { title: '#', field: 'idOrder' },
          { title: 'Firstname', field: 'firstname' },
          { title: 'Lastname', field: 'lastname' },
          {
            title: 'Tracking no', field: 'urlPdf', render: (rowData: any) => {
              if (rowData.urlPdf) {
                return <Link
                  component='a'
                  download
                  href={rowData.urlPdf}
                  variant='h6'
                >
                  {rowData.trackingNumber}
                </Link>
              }

              return '-';
            }
          },
          {
            title: 'Status', field: 'status', render: (rowData: any) => {
              if (rowData.status) {
                return (
                  <div className={classes.statusContainer}>
                    <StatusBullet
                      className={classes.status}
                      color={statusColors[rowData.status]}
                      size="sm"
                    />
                    {rowData.status} {rowData.status === 'refund' ? `(${rowData.amountRefunded})` : null}
                  </div>
                );
              }
            }
          },
          { title: 'Date', field: 'dateAdd', render: (rowData: any) => moment(rowData.dateAdd).format('DD/MM/YYYY'), filtering: false },
        ]}
        data={query =>
          new Promise((resolve) => {
            const filter = applyFilters(query.filters);

            API.get('returns', {
              params: {
                pageSize: query.pageSize,
                page: (query.page + 1),
                orderBy: query.orderBy?.field || 'dateAdd',
                orderDirection: query.orderDirection,
                search: query.search,
                $filter: filter
              }
            })
              .then(res => {
                if (res.status === 200) {
                  return res.data;
                }
              })
              .then(data => {
                setIsLoading(false);
                setErrorMsg('');
                resolve({
                  data: data.returns,
                  page: data.page - 1,
                  totalCount: data.count
                });
              })
              .catch(err => {
                setIsLoading(false);
                if (err?.response?.data?.message?.details) {
                  setErrorMsg(err.response.data.message.details[0].message);
                }
                else {
                  setErrorMsg('Network Error');
                }
                setOpenToast(true);
                resolve({
                  data: [],
                  page: 0,
                  totalCount: 0
                });
              });
          })}
        detailPanel={rowData => {
          return (
            <DisplayProducts
              address1={rowData.address1}
              postcode={rowData.postcode}
              city={rowData.city}
              email={rowData.email}
              carrier={rowData.carrier}
              products={rowData.products}
              currency={rowData.currency}
              company={rowData.company}
              totalMarchandizePrice={rowData.totalMarchandizePrice}
              totalPaid={rowData.totalPaid}
              totalTax={rowData.totalTax}
              paymentMethods={rowData.paymentMethods}
              discountsOrder={rowData.discountsOrder}
            />
          )
        }}
        onRowClick={(_event, _rowData, togglePanel) => {
          if (togglePanel) togglePanel();
        }}
        actions={[
          {
            icon: 'filter_list',
            tooltip: 'Toogle filter',
            isFreeAction: true,
            onClick: (_event) => setFilterActive(!filterActive)
          },
          {
            icon: 'edit',
            tooltip: 'Edit status',
            onClick: async (_event, rowData) => {
              const sumProduct = rowData.products.reduce((acc: any, current: any) => acc + (current.price || 0), 0);

              setOpenDialog(true);
              setReturnObj({
                idOrder: rowData.idOrder,
                source: rowData.source,
                amountRefunded: sumProduct
              });
            }
          }
        ]}
        options={{
          pageSize: 10,
          pageSizeOptions: [10, 50, 100],
          actionsColumnIndex: -1,
          filtering: filterActive
        }}
      />

      <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Refund</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Amount refunded"
            type="text"
            name='amountRefunded'
            onChange={handleInputChange}
            value={returnObj.amountRefunded || ''}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
            </Button>
          <Button onClick={handleSave} color="primary">
            Refund
            </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={openToast} autoHideDuration={6000} onClose={handleClose}>
        <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity="error">
          {errorMsg}
        </MuiAlert>
      </Snackbar>
    </div>
  )
};

export default ReturnsTable;
