import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import AuthService from '../../context/AuthService';

interface Props {
  Layout: any;
  Component: any;
  exact: boolean;
  path: string;
};

const Auth = new AuthService();

const PrivateRouteWithLayout: React.FC<Props> = ({ Layout, Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        Auth.loggedIn() ? (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        ) : (
          <Redirect to='/sign-in' />
        )
      )}
    />
  )
};

export default PrivateRouteWithLayout;
