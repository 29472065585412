import axios from 'axios';
import jwt_decode from 'jwt-decode';

import { encodeurl } from '../helpers/utils';

export default class AuthService {
  login(email: string, password: string) {
    // Get a token
    const data = {
      email: email,
      password: password
    };
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Client-Id': process.env.REACT_APP_CLIENT_ID,
        'Client-Secret': process.env.REACT_APP_CLIENT_SECRET
      }
    };

    return axios.post(`${process.env.REACT_APP_BASE_URL}/authenticate?${encodeurl(data)}`, null, headers).then(res => {
      if (res.status === 200) {
        this.setToken(res.data.accessToken);
        return Promise.resolve(res);
      } else {
        this.logout();
        return Promise.resolve(res);
      }
    });
  }

  loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken()
    return !!token && !this.isTokenExpired(token)
  }

  isTokenExpired(token: string): boolean {
    try {
      const jwt: any = jwt_decode(token);
      return (jwt.exp < Date.now() / 1000);
    } catch (err) {
      return false
    }
  }

  getExpirationTime(): number {
    const jwt: any = jwt_decode(this.getToken() as string);

    return +jwt.exp;
  }

  setToken(idToken: string) {
    // Saves user token to localStorage
    window.localStorage.setItem('omsglaltesse_token', idToken);
  }

  getToken() {
    // Retrieves the user token from localStorage
    return window.localStorage.getItem('omsglaltesse_token');
  }

  logout() {
    // Clear user token and profile data from localStorage
    window.localStorage.removeItem('omsglaltesse_token');
  }
}
