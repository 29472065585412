import { ThemeProvider } from '@material-ui/styles';
import { createBrowserHistory } from 'history';
import React, { createContext } from 'react';
import { Router } from 'react-router-dom';
import Routes from './Routes';
import theme from './theme';

const browserHistory = createBrowserHistory();

const App: React.FC = () => {
  const AuthContext = createContext(null);

  return (
    <AuthContext.Provider value={null}>
      <ThemeProvider theme={theme}>
        <Router history={browserHistory}>
          <Routes />
        </Router>
      </ThemeProvider>
    </AuthContext.Provider>
  )
}

export default App
