import axios from 'axios';
import AuthService from '../context/AuthService';
import { useHistory } from 'react-router-dom';

const Auth = new AuthService();

const ajax = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/`,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${Auth.getToken()}`,
    'Client-Id': process.env.REACT_APP_CLIENT_ID,
    'Client-Secret': process.env.REACT_APP_CLIENT_SECRET
  }
});

ajax.interceptors.request.use(
  (config) => {
    const token = Auth.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    else {
      const history = useHistory();
      Auth.logout();
      history.replace('/sign-in');
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default ajax;
