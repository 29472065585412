import { AppBar, Toolbar } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  root: {
  },
  img: {
    width: '60px'
  }
}))

const Topbar: React.FC = ({ ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar
      {...rest}
      className={ classes.root }
      color='primary'
      position='fixed'
    >
      <Toolbar>
        <RouterLink to='/orders'>
          <img
            className={classes.img}
            alt='Logo'
            src='/images/logos/logo.jpg'
          />
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
