import { Divider, Drawer, Theme } from '@material-ui/core';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { SidebarNav } from './components';

interface Props {
  open: any;
  variant: any;
  onClose: any;
};

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar: React.FC<Props> = ({ open, variant, onClose }) => {
  const classes = useStyles();

  const pages = [
    {
      title: 'Orders',
      href: '/orders',
      icon: <SupervisedUserCircleIcon />
    },
    {
      title: 'Returns',
      href: '/returns',
      icon: <GroupWorkIcon />
    }
  ];

  return (
    <Drawer
      anchor='left'
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        className={ classes.root }
      >
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          pages={pages}
        />
      </div>
    </Drawer>
  );
};

export default Sidebar;
