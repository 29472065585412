import { Grid, ListItem, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

interface Props {
  address1: string;
  postcode: string;
  city: string;
  company: string;
  email: string;
  carrier: string;
  products: {
    name: string;
    productQuantity: number;
    price: number;
    adjustedPrice: number;
    _id: string;
    reference: string;
    discountsProduct: {
      discountProductName: string;
      discountProductValue: number;
    }[];
  }[];
  currency: string;
  totalMarchandizePrice: number;
  totalPaid: number;
  totalTax: number;
  paymentMethods: {
    name: string;
    paid: number;
  }[];
  discountsOrder: {
    discountOrderName: string;
    discountOrderValue: number;
  }[];
}

const useStyles = makeStyles((theme: Theme) => ({
  customerContainer: {
    margin: theme.spacing(2),
  },
  priceContainer: {
    float: "right",
    margin: theme.spacing(2),
    textAlign: "right",
  },
  paymentMContainer: {
    float: "right",
    margin: theme.spacing(2),
    textAlign: "right",
  },
  italicTxt: {
    fontStyle : "italic",
    fontSize: 10,
  }
}));

//Correspondence between the payment method name and value to display
const mapPaymentMethodName : { [key: string] : string } = {
  "CREDIT_CARD" : "ADYEN",
  "ADYEN"       : "PAYPAL"
}

const DisplayProducts: React.FC<Props> = ({
  address1,
  postcode,
  city,
  email,
  company,
  carrier,
  products,
  currency,
  totalPaid,
  totalTax,
  totalMarchandizePrice,
  paymentMethods,
  discountsOrder,
}) => {
  const classes = useStyles();

  let totalMarchandizePriceOrderDisplay,
    adjustedPriceOrderDisplay,
    totalProductWithoutDiscountOrder = 0,
    totalTaxOrderDisplay;

  if (!totalMarchandizePrice) {
    adjustedPriceOrderDisplay = "-";
    totalMarchandizePriceOrderDisplay = "-";
  } else {
    adjustedPriceOrderDisplay = String(Math.round((totalPaid - totalMarchandizePrice) * 100) / 100) + " " + currency;
    totalMarchandizePriceOrderDisplay = totalMarchandizePrice + " " + currency;
  }

  if (!totalTax) {
    totalTaxOrderDisplay = "-";
  } else {
    totalTaxOrderDisplay = totalTax + " " + currency;
  }

  return (
    <div>
      <Grid container spacing={5}>
        <Grid item xs={6} spacing={5}>
          <div className={classes.customerContainer}>
            <div>
              {" "}
              {address1} ({postcode}) - {city}
            </div>
            <div> {email} </div>
            <div> {carrier} </div>
            <div> Company : {company ? company : "-"} </div>
          </div>
        </Grid>
      </Grid>
      {products.map((product) => {
        let unitPriceDisplay,
          unitAdjustmentDisplay,
          paidProductDisplay,
          adjustementPriceDisplay,
          priceProductDisplay;
        if (!product.price) {
          priceProductDisplay = "-";
          unitPriceDisplay = "-";
        } else {
          priceProductDisplay = (product.price * product.productQuantity) + " " + currency;
          unitPriceDisplay = product.price + " " + currency;
        }

        if (!product.adjustedPrice) {
          unitAdjustmentDisplay = "-";
          paidProductDisplay = String(product.price) + " " + currency;
          adjustementPriceDisplay = "-";
          totalProductWithoutDiscountOrder += product.price;
        } else {
          unitAdjustmentDisplay = String(Math.round((product.adjustedPrice / product.productQuantity) * 100) / 100) + " " + currency;
          paidProductDisplay = product.adjustedPrice + " " + currency;
          adjustementPriceDisplay = product.price ? String(Math.round(((product.price * product.productQuantity) - product.adjustedPrice) * 100) / 100) + " " + currency : "-";
          totalProductWithoutDiscountOrder += product.adjustedPrice;
        }

        return (
          <ListItem key={`display-product-${product._id}`} divider>
            <Grid item xs={6} spacing={5}>
              {product.name} ({product.reference}) {"\n"}
              {product.discountsProduct.map((discountsProduct) => {
                return (
                  <Grid container>
                    <Grid item>
                      <div className={classes.italicTxt}>
                        {discountsProduct.discountProductName ? discountsProduct.discountProductName : "" } {discountsProduct.discountProductValue ? "(" + discountsProduct.discountProductValue + " " + currency + ")" : ""}
                      </div>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
            <Grid container item xs={6} spacing={5}>
              <Grid item xs={8} spacing={5}>
                <div className={classes.priceContainer}>
                  <div> Unit Price : </div>
                  <div> Unit Adjusted : </div>
                  <div> Price : </div>
                  <div> Adjustment : </div>
                  <div> Paid : </div>
                  <div> Quantity : </div>
                </div>
              </Grid>
              <Grid item xs={4} spacing={5}>
                <div className={classes.priceContainer}>
                  <div> {unitPriceDisplay} </div>
                  <div> {unitAdjustmentDisplay} </div>
                  <div> {priceProductDisplay} </div>
                  <div> {adjustementPriceDisplay} </div>
                  <div> {paidProductDisplay} </div>
                  <div> x{product.productQuantity} </div>
                </div>
              </Grid>
            </Grid>
          </ListItem>
        );
      })}

      <ListItem divider>
        <Grid item xs={6} spacing={5}>
          {discountsOrder.map((discountOrder) => {
            return (
              <Grid container>
                <Grid item>
                  <div className={classes.italicTxt}>
                    {discountOrder.discountOrderName ? discountOrder.discountOrderName : "" } {discountOrder.discountOrderValue ? "(" + discountOrder.discountOrderValue + " " + currency + ")" : ""}
                  </div>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
        <Grid container item xs={6} spacing={5}>
          <Grid item xs={8} spacing={5}>
            <div className={classes.priceContainer}>
              <div> Total Price : </div>
              <div> Total Price (Discounted product) : </div>
              <div> Total discount : </div>
              <div>
                {" "}
                <b>Total Paid : </b>{" "}
              </div>
              <div> Tax Total Included : </div>
            </div>
          </Grid>
          <Grid item xs={4} spacing={5}>
            <div className={classes.priceContainer}>
              <div> {totalMarchandizePriceOrderDisplay} </div>
              <div> {totalProductWithoutDiscountOrder} {currency} </div>
              <div> {adjustedPriceOrderDisplay} </div>
              <div>
                {" "}
                {totalPaid} {currency}{" "}
              </div>
              <div> {totalTaxOrderDisplay} </div>
            </div>
          </Grid>
        </Grid>
      </ListItem>

      <ListItem divider>
        <Grid item xs={6} spacing={5}>
          PAYMENT METHODS
        </Grid>
        {paymentMethods.map((method, i) => {
          var name = method.name.toUpperCase();
          return (
            <Grid key={i} container item xs={6} spacing={5}>
              <Grid item xs={8} spacing={5}>
                <div className={classes.paymentMContainer}>
                  <div> {name in mapPaymentMethodName ? mapPaymentMethodName[name] : name} : </div>
                </div>
              </Grid>
              <Grid item xs={4} spacing={5}>
                <div className={classes.paymentMContainer}>
                  <div> {method.paid} {currency}</div>
                </div>
              </Grid>
            </Grid>
          );
        })}
      </ListItem>
    </div>
  );
};

export default React.memo(DisplayProducts);
