import moment from 'moment';
import React, { useEffect } from 'react';
import AuthService from '../../context/AuthService';
import { padStart } from '../../helpers/utils';

const Timer: React.FC = () => {
  const Auth = new AuthService();
  const eventTime = Auth.getExpirationTime();
  const currentTime = Date.now() / 1000;
  const diffTime = eventTime - currentTime;
  const interval = 1000;
  let duration: any = moment.duration(diffTime * 1000, 'milliseconds');
  const [timer, setTimer] = React.useState(duration);

  useEffect(() => {
    let mounted = true;
    timer.milliseconds() >= 0
      && setTimeout(() => {
        if (mounted) {
          setTimer(moment.duration(duration - interval, 'milliseconds'));
        }
      });

    return () => { mounted = false }
  }, [timer, duration, interval]);

  return (
    <div>
      <span>{`${padStart(timer.hours(), 2)}:${padStart(timer.minutes(), 2)}:${padStart(timer.seconds(), 2)}`}</span>
    </div>
  );
};

export default Timer;
