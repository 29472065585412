import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { Topbar } from './components'


interface Props {
  children: any;
};

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 64,
    height: '100%'
  },
  content: {
    height: '100%'
  }
}))

const Minimal: React.FC<Props> = ({ children }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Topbar />
      <main className={classes.content}>{children}</main>
    </div>
  )
};

export default Minimal
