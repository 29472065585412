import React from 'react';
import { Route } from 'react-router-dom';

interface Props {
  Layout: any;
  Component: any;
  exact: boolean;
  path: string;
}

const RouteWithLayout: React.FC<Props> = ({ Layout, Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  )
};

export default RouteWithLayout;
