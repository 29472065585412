import { each } from "underscore";
import { Filter } from "material-table";
import moment from "moment";

export const encodeurl = (params: { [key: string]: string }) => {
  return Object.keys(params)
    .map((key) => {
      return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
    })
    .join("&");
};

export const padStart = (num: number, size: number): string => {
  let s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
};

export const exportFile = (data: string, fileName: string) => {
  const exportedFileName = fileName + ".txt" || "export.txt";

  const blob = new Blob([data], {
    type: "application/octet-stream;charset=utf-8;",
  });
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, exportedFileName);
  } else {
    let link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFileName);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export const exportEntity = async (data: any, fileName: string) => {
  try {
    let computed = "$VERSION=110\r\n";
    computed += data
      .map((row: any) => {
        const BL = `${row.idOrder}`.padEnd(35);
        const filler2 = ``.padEnd(2);
        const filler677 = ``.padEnd(
          35 +
            10 +
            10 +
            10 +
            200 +
            1 +
            15 +
            6 * 35 +
            10 +
            35 +
            10 +
            35 +
            10 +
            3 +
            30 +
            18 +
            35
        );
        const filler312 = ``.padEnd(
          35 + 35 + 29 + 9 + 8 + 35 + 1 + 35 + 10 + 80 + 35
        );
        const filler222 = ``.padEnd(96 + 8 + 113 + 2 + 2 + 1);
        const filler10 = ``.padEnd(10);
        const filler15 = ``.padEnd(15);
        const filler20 = ``.padEnd(20);
        const filler25 = ``.padEnd(25);
        const filler35 = ``.padEnd(35);
        const weight = `00000050`;
        const lastname = `${row.lastname}`.padEnd(35).substring(0, 35);
        const companyAltesse = "Altesse Paris".padEnd(35).substring(0, 35);
        const firstname = `${row.firstname}`.padEnd(35).substring(0, 35);
        const postcode = `${row.postcode}`.padEnd(10);
        const postcodeAltesse = "07310".padEnd(10);
        const city = `${row.city}`.padEnd(35).substring(0, 35);
        const cityAltesse = "Saint Martin de Valamas"
          .padEnd(35)
          .substring(0, 35);
        const address1 = `${row.address1}`.padEnd(35).substring(0, 35);
        const address1Altesse = "255 routes de Valamas"
          .padEnd(35)
          .substring(0, 35);
        const isoCode = `${row.isoCode}`.padEnd(3);
        const isoCodeAltesse = `FR`.padEnd(3);
        let phone = row.shippingMobileNumber
          ? row.shippingMobileNumber
          : row.customerMobileNumber;
        phone = `${phone}`.padEnd(20).substring(0, 20);
        const gsm = `${phone}`.padEnd(35).substring(0, 35);
        const email = `${row.email}`.padEnd(80).substring(0, 80);
        const dateAdd = `${moment(row.dateAdd).format("DD/MM/YYYY")}`;
        const account = "00007912";
        const computedRow =
          BL +
          filler2 +
          weight +
          filler15 +
          lastname +
          firstname +
          filler35 +
          filler35 +
          filler35 +
          filler35 +
          postcode +
          city +
          filler10 +
          address1 +
          filler10 +
          isoCode +
          phone +
          filler25 +
          companyAltesse +
          filler35 +
          filler35 +
          filler35 +
          filler35 +
          filler35 +
          postcodeAltesse +
          cityAltesse +
          filler10 +
          address1Altesse +
          filler10 +
          isoCodeAltesse +
          filler20 +
          filler10 +
          filler35 +
          filler35 +
          filler35 +
          filler35 +
          dateAdd +
          account +
          filler312 +
          email +
          gsm +
          filler222 +
          "+" +
          filler677;
        return computedRow;
      })
      .join("\r\n");

    exportFile(computed, fileName);
  } catch (e) {}
};

export const applyFilters = (
  filters: Filter<any>[],
  operator: string = "AND"
) => {
  const length = filters.length;

  if (length) {
    let strFilters = "";

    each(filters, (filter, index) => {
      strFilters += `"${filter.column.field as string}":"${filter.value}"${
        index + 1 === length ? "" : ","
      }`;
    });

    return `{${strFilters}}`;
  }

  return undefined;
};
