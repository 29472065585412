import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Footer: React.FC = () => {
  const classes = useStyles();

  return (
    <div
      className={ classes.root }
    >
      <Typography variant='body1'>
        <Link
          component='a'
          href='mailto:developers@lesgeorgettes.com'
          variant='h6'
        >
          Technical problem?
        </Link>
      </Typography>
    </div>
  );
};

export default Footer;
