import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Theme, Grid } from '@material-ui/core'

import { OrdersTable } from './components'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const OrderList: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid item xs={12}>
          <OrdersTable />
        </Grid>
      </div>
    </div>
  )
};

export default OrderList;
