import { Button, Grid, IconButton, Link, Snackbar, TextField, Theme, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import validate from 'validate.js';
import AuthService from '../../context/AuthService';

const Auth = new AuthService();

interface Props {
  history: any;
};

interface User {
  email: string;
  password: string;
};

interface Form {
  isValid: boolean;
  values: User;
  touched: any;
  errors: any;
};

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  }
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
}));

const SignIn: React.FC<Props> = ({ history }) => {
  const classes = useStyles();

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [errorMsg, setErrorMsg] = useState('');

  // Handle toast
  const [openToast, setOpenToast] = React.useState(false);
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenToast(false);
  };

  const [formState, setFormState] = useState<Form>({
    isValid: false,
    values: { email: '', password: '' },
    touched: {},
    errors: {}
  })

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }))
  }, [formState.values]);

  const handleBack = () => {
    history.goBack();
  }

  const handleChange = (event: any) => {
    event.persist()

    setFormState({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    });
  }

  const handleSignIn = (event: any) => {
    event.preventDefault()

    Auth.login(formState.values.email, formState.values.password)
      .then(res => {
        if (res.status === 200) {
          setIsLoggedIn(true);
          setErrorMsg('');
          history.replace('/orders');
        } else {
          setIsLoggedIn(false);
          setErrorMsg('');
          setOpenToast(true);
          history.replace('/sign-in');
        }
      })
      .catch(err => {
        setIsLoggedIn(false);
        setErrorMsg('Network Error');
        setOpenToast(true);
        history.replace('/sign-in');
      });
  }

  const hasError = (field: string) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  if (Auth.loggedIn() || isLoggedIn) {
    return (
      <Redirect to='/not-found' />
    )
  }

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.quoteContainer}
          item
          lg={5}
        >
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography
                className={classes.quoteText}
                variant='h1'
              >
                Interface d'administration des ventes
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid
          className={classes.content}
          item
          lg={7}
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.contentBody}>
              <form
                className={classes.form}
                onSubmit={handleSignIn}
              >
                <Typography
                  className={classes.title}
                  variant='h2'
                >
                  Sign in
                </Typography>
                <Typography
                  align='center'
                  className={classes.sugestion}
                  color='textSecondary'
                  variant='body1'
                >
                  Login with email address
                </Typography>
                <TextField
                  className={classes.textField}
                  error={hasError('email')}
                  fullWidth
                  helperText={
                    hasError('email') ? formState.errors.email[0] : null
                  }
                  label='Email address'
                  name='email'
                  onChange={handleChange}
                  type='text'
                  value={formState.values.email || ''}
                  variant='outlined'
                />
                <TextField
                  className={classes.textField}
                  error={hasError('password')}
                  fullWidth
                  helperText={
                    hasError('password') ? formState.errors.password[0] : null
                  }
                  label='Password'
                  name='password'
                  onChange={handleChange}
                  type='password'
                  value={formState.values.password || ''}
                  variant='outlined'
                  autoComplete='true'
                />
                <Button
                  className={classes.signInButton}
                  color='primary'
                  disabled={!formState.isValid}
                  fullWidth
                  size='large'
                  type='submit'
                  variant='contained'
                >
                  Sign in now
                </Button>
                <Typography
                  color='textSecondary'
                  variant='body1'
                >
                  Don't have an account?{' '}
                  <Link
                    component='a'
                    href='mailto:developers@lesgeorgettes.com'
                    variant='h6'
                  >
                    Contact us
                  </Link>
                </Typography>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>

      <Snackbar open={openToast} autoHideDuration={6000} onClose={handleClose}>
        <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity="error">
          {errorMsg}
        </MuiAlert>
      </Snackbar>
    </div>
  )
};

export default withRouter(SignIn);
