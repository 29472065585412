import { AppBar, Hidden, IconButton, Theme, Toolbar } from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import AuthService from '../../../../context/AuthService';
import Timer from '../../../../components/Timer';

const Auth = new AuthService();

const useStyles = makeStyles((theme: Theme) => ({
  root: {
  },
  flexGrow: {
    flexGrow: 1
  },
  img: {
    width: '60px'
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  }
}));

interface Props {
  onSidebarOpen: any;
};

const Topbar: React.FC<Props> = ({ onSidebarOpen }) => {
  const history = useHistory();

  const classes = useStyles();

  const logout = () => {
    Auth.logout();
    history.replace('/sign-in');
  }

  return (
    <AppBar
      className={classes.root}
    >
      <Toolbar>
        <RouterLink to='/orders'>
          <img
            className={classes.img}
            alt='Logo'
            src='/images/logos/logo.jpg'
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <IconButton
            className={classes.signOutButton}
            color='inherit'
            onClick={logout}
          >
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color='inherit'
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <Timer />
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
